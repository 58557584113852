import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDuration'
})
export class TimeDurationPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let absVal = Math.abs(value)
    let result = "-";
    if (typeof absVal === 'number') {
      const hours = Math.floor(absVal);
      const minute = +Math.ceil(((absVal - hours) * 60));

      if (hours == 0 && minute == 0) {
        result = "-";
      } else if (hours == 0 && minute != 0) {
        result = `${minute} m`;
      } else if (hours != 0 && minute == 0) {
        result = `${hours} h`;
      } else {
        result = `${hours} h ${minute} m`;
      }
    }
    return result;
  }

}
