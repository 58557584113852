import { Injectable } from '@angular/core';
import { IUser } from '@app/model.interface';

import { CONFIG } from './sidePanelRouterNavConfig.const';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userData: IUser;

  constructor() {
    if (localStorage.getItem('data')) {
      this.userData = JSON.parse(localStorage.getItem('data')).user;
    }

  }

  public setUserData(data: IUser) {
    this.userData = { ...data };
  }

  public isLoggedIn(): boolean {
    return localStorage.getItem('token') ? true : false;
  }

  public getSidePanelRouterNavList() {
    return CONFIG[this.userData.userRole] || [];

  }

  public getUserRole() {
    return this.userData.userRole;
  }

  public getLoggedUserId() {
    return this.userData.id;
  }

}
