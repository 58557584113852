import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { LoginGuard } from './login.guard';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    }, {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [LoginGuard],
        children: [
            {
                path: '',
                loadChildren: './dashboard/dashboard.module#DashboardModule'
            }, {
                path: 'institute',
                loadChildren: './institute/institute.module#InstituteModule'
            }, {
                path: 'faculty',
                loadChildren: './faculty/faculty.module#FacultyModule'
            }, {
                path: 'slabs',
                loadChildren: './slabs/slabs.module#SlabsModule'
            }, {
                path: 'timetable',
                loadChildren: './timetable/timetable.module#TimetableModule'
            }, {
                path: 'report',
                loadChildren: './report/report.module#ReportModule'
            }, {
                path: 'logs',
                loadChildren: './logs/logs.module#LogsModule'
            }, {
                path: 'exceeded-logs',
                loadChildren: './exceeded-logs/exceeded-logs.module#ExceededLogsModule'
            }, {
                path: 'profile',
                loadChildren: './profile/profile.module#ProfileModule'
            }


        ]
    }, {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: '',
            loadChildren: './pages/pages.module#PagesModule'
        }]
    }
];
