import { Injectable } from '@angular/core';
// import { ErrorDialogService } from '../error-dialog/errordialog.service';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import { LoaderService } from '../services/loader/loader.service';
import { finalize, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { error } from 'protractor';

import { NotificationService } from '@app/shared/services/notification/notification.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private baseURL = 'https://coursetrack.rayseducation.org';
  // private baseURL = 'http://localhost:8080';
  constructor(public loaderService: LoaderService, private route: Router, private notificationService: NotificationService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.show();
    let isRequestValid = true;
    if (request.url.indexOf('/api/') === 0) {


      if (request.url.indexOf('/api/users/login') === 0) {
        const authHeader = 'Basic ' + btoa(`${request.body.name}:${request.body.password}`);
        request = request.clone({ headers: request.headers.set('Authorization', authHeader) });
        request = request.clone({ body: {} });
      } else {
        const token: string = localStorage.getItem('token');

        if (token) {
          request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        } else {
          isRequestValid = false;
          this.notificationService.errorNotify('Please login to continue');
          this.route.navigate(['/login']);
        }

      }
      if (isRequestValid) {
        request = request.clone({ url: `${this.baseURL}${request.url}` });



        // if (!request.headers.has('Content-Type')) {
        //   request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        //   console.log('Inside if')
        // }



        return next.handle(request).pipe(
          catchError((errorObj: any, caught: Observable<any>) => {
            this.loaderService.hide();
            switch (errorObj.status) {
              case 401:
                this.notificationService.errorNotify(errorObj.error.reason);
                // Check not the login api error
                if (errorObj.error.reason !== "Incorrect username or password") {
                  localStorage.clear();
                  this.route.navigate(['/login']);
                }
                break;
              case 403:
                this.route.navigate(['/dashboard']);
                break;

            }
            return throwError(errorObj);
          }),
          finalize(() => { this.loaderService.hide(); })

          // tap(evt => {
          //     console.log(evt)
          //     if (evt instanceof HttpResponse) {
          //         this.loaderService.hide();
          //     }
          // }),
        );
      }

    }





  }
}
