import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { RouteInfo, ChildrenItems } from '@app/model.interface';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { from } from 'rxjs';

declare const $: any;




@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ps: any;
    constructor(private authService: AuthService) {

    }
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }

    ngOnInit() {

        this.menuItems =
            this.authService.getSidePanelRouterNavList()
                .filter(menuItem => menuItem);

        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }
    }
    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
}


//Menu Items
// export const ROUTES: RouteInfo[] = [{
//     path: '/dashboard',
//     title: 'Dashboard',
//     type: 'link',
//     icontype: 'dashboard'
// }, {
//     path: '/institute',
//     title: 'Institute',
//     type: 'sub',
//     icontype: 'apartment',
//     collapse: 'institute',
//     children: [
//         { path: 'centers', title: 'Centers', ab: 'CEN' },
//         { path: 'courses', title: 'Courses', ab: 'CRS' },
//         { path: 'subjects', title: 'Subjects', ab: 'SUB' },
//         { path: 'chapters', title: 'Chapters', ab: 'CHPT' }
//     ]
// }, {
//     path: '/faculty',
//     title: 'Faculty',
//     type: 'sub',
//     icontype: 'emoji_people',
//     collapse: 'faculty',
//     children: [
//         // { path: 'register', title: 'Register', ab: 'RGS' },
//         { path: 'faculty-list', title: 'Faculty List', ab: 'FLT' },
//         { path: 'batch-in-charge', title: 'Batch In Charge', ab: 'BIC' }
//     ]
// }, {
//     path: '/slabs',
//     title: 'Slab',
//     type: 'sub',
//     icontype: 'view_week',
//     collapse: 'slabs',
//     children: [
//         { path: 'slabs-types', title: 'Slab Types', ab: 'SLB' },
//         // { path: 'slab-rate', title: 'Slab Types', ab: 'STP' }
//     ]
// }, {
//     path: '/timetable',
//     title: 'Timetable',
//     type: 'sub',
//     icontype: 'grid_on',
//     collapse: 'timetable',
//     children: [
//         { path: 'upload-time-table', title: 'Upload Time Table', ab: 'UTT' }
//     ]
// }, {
//     path: '/approve-timing',
//     title: 'Approve Timing',
//     type: 'sub',
//     icontype: 'timer',
//     collapse: 'approve-timing',
//     children: [
//         { path: 'all-requests', title: 'All Requests', ab: 'AR' },
//         { path: 'report', title: 'Report', ab: 'RPT' },
//         { path: 'consolidated-report', title: 'Consolidated Report', ab: 'CR' },
//         { path: 'daily-report', title: 'Daily Report', ab: 'DR' }]
// }, {
//     path: '/report',
//     title: 'Report',
//     type: 'sub',
//     icontype: 'poll',
//     collapse: 'report',
//     children: [
//         { path: 'report', title: 'Report', ab: 'RPT' }]
// }
//     // {
//     //     path: '/components',
//     //     title: 'Components',
//     //     type: 'sub',
//     //     icontype: 'apps',
//     //     collapse: 'components',
//     //     children: [
//     //         { path: 'buttons', title: 'Buttons', ab: 'B' },
//     //         { path: 'grid', title: 'Grid System', ab: 'GS' },
//     //         { path: 'panels', title: 'Panels', ab: 'P' },
//     //         { path: 'sweet-alert', title: 'Sweet Alert', ab: 'SA' },
//     //         { path: 'notifications', title: 'Notifications', ab: 'N' },
//     //         { path: 'icons', title: 'Icons', ab: 'I' },
//     //         { path: 'typography', title: 'Typography', ab: 'T' }
//     //     ]
//     // },
//     // {
//     //     path: '/forms',
//     //     title: 'Forms',
//     //     type: 'sub',
//     //     icontype: 'content_paste',
//     //     collapse: 'forms',
//     //     children: [
//     //         {path: 'regular', title: 'Regular Forms', ab:'RF'},
//     //         {path: 'extended', title: 'Extended Forms', ab:'EF'},
//     //         {path: 'validation', title: 'Validation Forms', ab:'VF'},
//     //         {path: 'wizard', title: 'Wizard', ab:'W'}
//     //     ]
//     // },{
//     //     path: '/tables',
//     //     title: 'Tables',
//     //     type: 'sub',
//     //     icontype: 'grid_on',
//     //     collapse: 'tables',
//     //     children: [
//     //         {path: 'regular', title: 'Regular Tables', ab:'RT'},
//     //         {path: 'extended', title: 'Extended Tables', ab:'ET'},
//     //         {path: 'datatables.net', title: 'Datatables.net', ab:'DT'}
//     //     ]
//     // },{
//     //     path: '/maps',
//     //     title: 'Maps',
//     //     type: 'sub',
//     //     icontype: 'place',
//     //     collapse: 'maps',
//     //     children: [
//     //         {path: 'google', title: 'Google Maps', ab:'GM'},
//     //         {path: 'fullscreen', title: 'Full Screen Map', ab:'FSM'},
//     //         {path: 'vector', title: 'Vector Map', ab:'VM'}
//     //     ]
//     // },{
//     //     path: '/widgets',
//     //     title: 'Widgets',
//     //     type: 'link',
//     //     icontype: 'widgets'

//     // },{
//     //     path: '/charts',
//     //     title: 'Charts',
//     //     type: 'link',
//     //     icontype: 'timeline'

//     // },{
//     //     path: '/calendar',
//     //     title: 'Calendar',
//     //     type: 'link',
//     //     icontype: 'date_range'
//     // },{
//     //     path: '/pages',
//     //     title: 'Pages',
//     //     type: 'sub',
//     //     icontype: 'image',
//     //     collapse: 'pages',
//     //     children: [
//     //         {path: 'pricing', title: 'Pricing', ab:'P'},
//     //         {path: 'timeline', title: 'Timeline Page', ab:'TP'},
//     //         {path: 'login', title: 'Login Page', ab:'LP'},
//     //         {path: 'register', title: 'Register Page', ab:'RP'},
//     //         {path: 'lock', title: 'Lock Screen Page', ab:'LSP'},
//     //         {path: 'user', title: 'User Page', ab:'UP'}
//     //     ]
//     // }
// ];