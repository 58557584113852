import { Pipe, PipeTransform } from '@angular/core';
interface Item {
  name?: string
}

@Pipe({
  name: 'listObjName'
})
export class ListObjNamePipe implements PipeTransform {

  transform(valueList: Item[], args?: any): any {
    return valueList.map((item) => item.name).join(', ');
  }

}
