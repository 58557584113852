const admin = [{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard'
}, {
    path: '/institute',
    title: 'Institute',
    type: 'sub',
    icontype: 'apartment',
    collapse: 'institute',
    children: [
        { path: 'centers', title: 'Centers', ab: 'CEN' },
        { path: 'courses', title: 'Courses', ab: 'CRS' },
        { path: 'subjects', title: 'Subjects', ab: 'SUB' },
        { path: 'chapters', title: 'Chapters', ab: 'CHPT' }
    ]
}, {
    path: '/faculty',
    title: 'Faculty',
    type: 'sub',
    icontype: 'emoji_people',
    collapse: 'faculty',
    children: [
        // { path: 'register', title: 'Register', ab: 'RGS' },
        { path: 'faculty-list', title: 'Faculty List', ab: 'FLT' },
        { path: 'batch-in-charge', title: 'Batch In Charge', ab: 'BIC' }
    ]
}, {
    path: '/slabs',
    title: 'Slab',
    type: 'sub',
    icontype: 'view_week',
    collapse: 'slabs',
    children: [
        { path: 'slabs-types', title: 'Slab Types', ab: 'SLB' },
        // { path: 'slab-rate', title: 'Slab Types', ab: 'STP' }
    ]
}, {
    path: '/timetable',
    title: 'Timetable',
    type: 'sub',
    icontype: 'grid_on',
    collapse: 'timetable',
    children: [
        { path: 'upload-time-table', title: 'Upload Time Table', ab: 'UTT' }
    ]
}, {
    path: '/report',
    title: 'Report',
    type: 'sub',
    icontype: 'poll',
    collapse: 'report',
    children: [
        { path: 'report', title: 'Report', ab: 'RPT' }
    ]
},
{
    path: '/logs',
    title: 'Logs',
    type: 'sub',
    icontype: 'description',
    collapse: 'logs',
    children: [
        { path: 'add', title: 'Add', ab: 'ADD' },
        { path: 'view', title: 'View', ab: 'VW' }
    ]
},
{
    path: '/profile',
    title: 'Profile',
    type: 'sub',
    icontype: 'face',
    collapse: 'profile',
    children: [
        { path: 'view', title: 'View Profile', ab: 'VP' }]
}
];
const faculty = [{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard'
}, {
    path: '/report',
    title: 'Report',
    type: 'sub',
    icontype: 'poll',
    collapse: 'report',
    children: [
        { path: 'report', title: 'Report', ab: 'RPT' }]
}, {
    path: '/profile',
    title: 'Profile',
    type: 'sub',
    icontype: 'face',
    collapse: 'profile',
    children: [
        { path: 'view', title: 'View Profile', ab: 'VP' }]
}
];

const supervisor = [{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard'
}, {
    path: '/report',
    title: 'Report',
    type: 'sub',
    icontype: 'poll',
    collapse: 'report',
    children: [
        { path: 'report', title: 'Report', ab: 'RPT' }]
}, {
    path: '/logs',
    title: 'Exceeds Log',
    type: 'sub',
    icontype: 'description',
    collapse: 'logs',
    children: [
        { path: 'pending-log-summary', title: 'Pending Logs', pageTitle: 'Pending Logs Summary', ab: 'PND' },
        { path: 'history', title: 'History', ab: 'HIS' }
    ]
}, {
    path: '/profile',
    title: 'Profile',
    type: 'sub',
    icontype: 'face',
    collapse: 'profile',
    children: [
        { path: 'view', title: 'View Profile', ab: 'VP' }]
}];
const verifier = [{
    path: '/logs',
    title: 'Logs',
    type: 'sub',
    icontype: 'description',
    collapse: 'logs',
    children: [
        { path: 'pending-log-summary', title: 'Pending Logs', pageTitle: 'Pending Logs Summary', ab: 'PND' },
    ]
},
{
    path: '/report',
    title: 'Reports',
    type: 'sub',
    icontype: 'poll',
    collapse: 'report',
    children: [
        { path: 'chapter-report', title: 'Chapter Report', pageTitle: 'Chapter Report', ab: 'CR' },
        { path: 'allocation-report', title: 'Faculty Allocations', pageTitle: 'Faculty Allocations', ab: 'FA' },
        { path: 'batch-report', title: 'Batch Report', pageTitle: 'Batch Report', ab: 'BR' },
        { path: 'subject-report', title: 'Subject Report', pageTitle: 'Subject Report', ab: 'SR' },
        { path: 'faculty-overview', title: 'Faculty Summary', pageTitle: 'Faculty Summary', ab: 'FS' },
        { path: 'faculty-report', title: 'Faculty Logs', pageTitle: 'Faculty Logs', ab: 'FL' }]
},
];

const superVerifier = [{
    path: '/exceeded-logs',
    title: 'Exceeded Logs',
    type: 'sub',
    icontype: 'description',
    collapse: 'exceeded-logs',
    children: [
        { path: 'pending-log-summary', title: 'Pending Logs', pageTitle: 'Pending Logs Summary', ab: 'PND' },
        { path: 'history', title: 'History', ab: 'HIS' }
    ]
},
{
    path: '/report',
    title: 'Reports',
    type: 'sub',
    icontype: 'poll',
    collapse: 'report',
    children: [
        { path: 'chapter-report', title: 'Chapter Report', pageTitle: 'Chapter Report', ab: 'CR' },
        { path: 'allocation-report', title: 'Faculty Allocations', pageTitle: 'Faculty Allocations', ab: 'FA' },
        { path: 'batch-report', title: 'Batch Report', pageTitle: 'Batch Report', ab: 'BR' },
        { path: 'subject-report', title: 'Subject Report', pageTitle: 'Subject Report', ab: 'SR' },
        { path: 'faculty-overview', title: 'Faculty Summary', pageTitle: 'Faculty Summary', ab: 'FS' },
        { path: 'faculty-report', title: 'Faculty Logs', pageTitle: 'Faculty Logs', ab: 'FL' }]
}];
export const CONFIG = {
    admin,
    faculty,
    supervisor,
    verifier,
    superVerifier
};
