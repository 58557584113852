import { Injectable } from '@angular/core';
declare var jQuery: any;
import swal from 'sweetalert2';

const notificationTemplate = `<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">
<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss"> <i
    class="material-icons">close</i></button>
<i class="material-icons" data-notify="icon">notifications</i>
<span data-notify="title">{1}</span> <span data-notify="message">{2}</span>
<div class="progress" data-notify="progressbar">
  <div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0"
    aria-valuemax="100" style="width: 0%;"></div>
</div><a href="{3}" target="{4}" data-notify="url"></a>
</div>`;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor() { }

  notify(htmlTemplate) {
    // const type = ['', 'info', 'success', 'warning', 'danger', 'rose', 'primary'];

    // const color = Math.floor((Math.random() * 6) + 1);

    const from = 'top';
    const align = 'right';

    jQuery.notify({
      icon: 'notifications',
      message: htmlTemplate
    }, {
      type: 'success',
      timer: 2000,
      placement: {
        from: from,
        align: align
      },
      template: notificationTemplate
    });
  }
  errorNotify(htmlTemplate) {
    // const type = ['', 'info', 'success', 'warning', 'danger', 'rose', 'primary'];

    // const color = Math.floor((Math.random() * 6) + 1);

    const from = 'top';
    const align = 'right';

    jQuery.notify({
      icon: 'notifications',
      message: htmlTemplate
    }, {
      type: 'danger',
      timer: 2000,
      placement: {
        from: from,
        align: align
      },
      template: notificationTemplate
    });
  }
  askToCheck(text) {
    return swal({
      title: 'Are you sure?',
      text: text,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      buttonsStyling: false
    });
  }
}
